function isDevEnvironment(hostname: string): boolean {
  return ['vercel.app', 'localhost'].some((url) => hostname.includes(url))
}

export const SPG_SPM_ENVIRONMENTS_MAP: Record<string, string> = {
  'workflow.signavio.com': 'https://editor.signavio.com',
  'workflow-us.signavio.com': 'https://app-us.signavio.com',
  'workflow-au.signavio.com': 'https://app-au.signavio.com',
  'workflow-ca.signavio.com': 'https://app-ca.signavio.com',
  'workflow-jp.signavio.com': 'https://app-jp.signavio.com',
  'workflow-sgp.signavio.com': 'https://app-sgp.signavio.com',
  'workflow-kr.signavio.com': 'https://app-kr.signavio.com',
  'workflow.jpmc.signavio.com': 'https://editor.jpmc.signavio.com',
  'workflow.bugbounty.signavio.com': 'https://editor.bugbounty.signavio.com',
  'workflow-staging.signavio.com': 'https://staging.signavio.com',
}

export function getSPMURLByHostname(hostname: string): string {
  if (SPG_SPM_ENVIRONMENTS_MAP[hostname]) {
    return SPG_SPM_ENVIRONMENTS_MAP[hostname]
  }

  // Vercel Preview has a random hostname, so we can't add it to the config
  if (isDevEnvironment(hostname)) {
    return 'https://staging.signavio.com'
  }

  return 'https://editor.signavio.com'
}
